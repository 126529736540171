import React from "react";
import styles from "./EmptyState.module.scss";
import SpawningCross from "../../../../public/assets/icons/SpawningCross";

interface EmptyStateProps {
  children?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.cross}>
        <SpawningCross />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default EmptyState;
