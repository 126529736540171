"use client";

import React, { useEffect } from "react";
import styles from "./ErrorState.module.scss";
import Button from "@/components/Button/Button";

import * as Sentry from "@sentry/nextjs";

// params object
interface ErrorStateProps {
  message?: string | null;
}

const ErrorState: React.FC<ErrorStateProps> = ({ message }) => {
  useEffect(() => {
    Sentry.captureException(message);
  }, [message]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>Whoops something went wrong!</h1>
        <p>
          {message
            ? message
            : "Please try again. If the problem persists, contact support for assistance."}
        </p>

        <Button
          variant="secondary"
          onClick={() => {
            window.open("mailto:info@spawning.ai");
          }}
        >
          Contact us
        </Button>
      </div>
    </div>
  );
};

export default ErrorState;
