import React, { useEffect, useState } from "react";
import Image from "next/image";

import cn from "classnames";
import { IconButton, Tooltip } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReportIcon from "@mui/icons-material/Report";

import styles from "./UrlDataGridCard.module.scss";
import BrokenStateDataGridCard from "@/components/BrokenStateDataGridCard/BrokenStateDataGridCard";
import UrlCheckBox from "../UrlCheckBox/UrlCheckBox";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../app/GlobalRedux/store";
import { setMoreLikeThisModalInfo } from "../../../../app/GlobalRedux/Features/moreLikeThisModalSlice";
import { ItemDataType } from "@/types";
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import mixpanel from "mixpanel-browser";
import Logging from "@/utils/Logging";

interface UrlDataGridCardProps {
  url: string | null;
  isChecked: boolean;
  title: string | undefined | null;
  caption: string | null;
  authors: string[] | null | undefined;
  type?: ItemDataType;
  is_in_dataset?: boolean;
  source?: string;
  //   handleOpenMLT: (e: React.MouseEvent, currentUrl: string) => void;
  handleCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    url: string
  ) => void;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    url: string
  ) => void;
}

const UrlDataGridCard: React.FC<UrlDataGridCardProps> = ({
  url,
  isChecked,
  title,
  caption,
  authors,
  type,
  is_in_dataset,
  source,
  handleCheckBoxClick,
  handleKeyDown,
}) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const [brokenItemUrl, setBrokenItemUrl] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [isLocallyChecked, setIsLocallyChecked] = useState(isChecked);

  useEffect(() => {
    if (brokenItemUrl === url) {
      setIsLocallyChecked(false);
      return;
    }

    setIsLocallyChecked(isChecked);
  }, [brokenItemUrl, isChecked, url]);

  if (!url) {
    return null;
  }

  const dataSpecificElement: Record<ItemDataType, React.ReactNode> = {
    [ItemDataType.VIDEO]: (
      <div className={styles.videoPlayerWrapper}>
        <video
          src={url}
          controls
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <track kind="captions" />
        </video>
      </div>
    ),
    [ItemDataType.AUDIO]: (
      <div className={styles.audioPlayerWrapper}>
        {" "}
        <AudioPlayer url={url} className={styles.audioPlayer} />
      </div>
    ),
    [ItemDataType.CODE]: (
      <span className={styles.codePlaceholder}>
        {/* <CodeIcon fontSize="large" /> */}
        {/* <p>{extension}</p> */}
        code
      </span>
    ),
    [ItemDataType.TEXT]: null,
    [ItemDataType.IMAGE]: (
      <Image
        src={url}
        alt={"no caption found"}
        width={0}
        height={0}
        sizes="100vw"
        fill
        style={{
          objectFit: "contain",
        }}
        onError={() => {
          setBrokenItemUrl(url);
        }}
        unoptimized
      />
    ),
    [ItemDataType.OTHER]: (
      <Image
        src={url}
        alt={"no caption found"}
        width={0}
        height={0}
        sizes="100vw"
        fill
        style={{
          objectFit: "contain",
        }}
        onError={() => {
          setBrokenItemUrl(url);
        }}
        unoptimized
      />
    ),
  };

  const handleShare = () => {
    if (window && typeof window !== "undefined") {
      const shareUrl = `${window.location.origin}/share?url=${url}`;

      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          setUrlCopied(true);
        })
        .catch((err) => {
          Logging.event("Clipboard Error", {
            page: "Url Data Grid Card",
            err,
          });
        });
    }

    setTimeout(() => {
      setUrlCopied(false);
    }, 2000);
  };

  if (!url) {
    return null;
  }

  const getHost = (url: string) => {
    try {
      return new URL(url).host;
    } catch (error) {
      console.log(error, url);
    }
  };

  const host = getHost(url);

  const handleMoreLikeThis = async (url: string) => {
    mixpanel.track("More Like This", {
      type,
      host,
    });

    await dispatch(
      setMoreLikeThisModalInfo({ isOpen: true, image_url: url, caption })
    );
  };

  const sourceMessage: Record<string, string> = {
    EXTENSION:
      "This item was found in the extension results and has been preemptively opted out",
    ["LAION-5B"]: " This item was found in the Laion-5B dataset",
  };

  return (
    <div key={url} className={styles.dataGridCardWrapper}>
      {(type || is_in_dataset) && (
        <div className={styles.cardHeader}>
          <span className={styles.typeLozenge}>{type}</span>
          {source && (
            <Tooltip title={sourceMessage[source]}>
              <span className={styles.sourceLozenge}>{source}</span>
            </Tooltip>
          )}
          {is_in_dataset && (
            <Tooltip title="Image found in dataset">
              <ReportIcon />
            </Tooltip>
          )}
        </div>
      )}
      <div className={styles.elementWrapper}>
        <div className={styles.dataGridCard}>
          {type === ItemDataType.TEXT ? (
            <div className={styles.bookCover}>
              <div className={styles.bookTitle}>
                <h1>{title}</h1>
              </div>
              <div>{authors?.join(", ")}</div>
            </div>
          ) : brokenItemUrl !== url ? (
            <>{dataSpecificElement[type as ItemDataType]}</>
          ) : (
            <BrokenStateDataGridCard />
          )}
        </div>
      </div>

      {type !== ItemDataType.TEXT && (
        <div className={styles.captionWrapper}>
          <div
            className={cn(styles.captionButton, {
              [styles.noCaption]: !caption,
            })}
          >
            {caption || "no caption found"}
          </div>
          <div className={styles.sourceWrapper}>
            <div className={styles.source}>{host}</div>

            <IconButton href={url} target="_blank">
              <OpenInNewIcon fontSize="small" sx={{ color: "gray" }} />
            </IconButton>
          </div>
        </div>
      )}
      {type !== ItemDataType.TEXT && <div className={styles.divider} />}
      <div className={styles.cardFooter}>
        <div className={styles.actionsBar}>
          <UrlCheckBox
            url={url}
            isChecked={isLocallyChecked}
            handleCheckBoxClick={handleCheckBoxClick}
            handleKeyDown={handleKeyDown}
            disabled={brokenItemUrl === url}
          />
        </div>
      </div>
    </div>
  );
};

export default UrlDataGridCard;
