import React from "react";
import styles from "./CheckBox.module.scss";

interface CheckBoxProps {
  id?: number;
  isChecked?: boolean;
  isSelectAllCheckbox?: boolean;
  isModal?: boolean;
  labelText?: string;
  disabled?: boolean;
  handleOnSelectAllClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleKeyDownSelectAll?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  isChecked,
  isSelectAllCheckbox,
  isModal,
  labelText,
  disabled,
  handleOnSelectAllClick,

  handleCheckBoxClick,
  handleKeyDown,
  handleKeyDownSelectAll,
}) => {
  if (isSelectAllCheckbox && handleKeyDownSelectAll && !id && isModal) {
    return (
      <label
        htmlFor="select-all-modal-checkbox"
        className={styles.checkBoxLabel}
      >
        <input
          id="select-all-modal-checkbox"
          className={styles.checkboxInput}
          type="checkbox"
          onChange={handleOnSelectAllClick}
          onKeyDown={(e) => handleKeyDownSelectAll(e)}
        />
        {labelText}
      </label>
    );
  }
  if (isSelectAllCheckbox && handleKeyDownSelectAll && !id) {
    return (
      <label htmlFor="select-all-checkbox" className={styles.checkBoxLabel}>
        <input
          id="select-all-checkbox"
          className={styles.checkboxInput}
          type="checkbox"
          onChange={handleOnSelectAllClick}
          onKeyDown={(e) => handleKeyDownSelectAll(e)}
        />
        {labelText}
      </label>
    );
  }

  return (
    <label htmlFor={`checkbox-input-${id}`} className={styles.itemActions}>
      <span>
        {id && (
          <input
            id={`checkbox-input-${id}`}
            className={styles.checkboxInput}
            type="checkbox"
            disabled={disabled}
            checked={isChecked}
            onChange={(e) => handleCheckBoxClick && handleCheckBoxClick(e, id)}
            onKeyDown={(e) => handleKeyDown && handleKeyDown(e, id)}
          />
        )}
      </span>
    </label>
  );
};

export default CheckBox;
