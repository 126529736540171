import React from "react";

const SpawningCross = () => (
  <svg
    width="539"
    height="539"
    viewBox="0 0 539 539"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-2.5"
      x2="643.467"
      y2="-2.5"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 495 498)"
      stroke="black"
      strokeWidth="5"
    />
    <path
      d="M45.8964 44.6814C46.0949 45.22 45.5235 45.723 44.9721 45.4951L23.8821 36.002C23.8348 35.9842 23.7721 35.9502 23.708 35.9472L0.855941 31.8693C0.266309 31.7642 0.0937951 31.0243 0.591831 30.7048L19.6212 17.7575C19.6548 17.728 19.7052 17.6836 19.7388 17.654L34.8276 0.507018C35.2187 0.0735365 35.9794 0.295581 36.0313 0.874124L38.0766 23.2982C38.0736 23.3604 38.0873 23.4077 38.101 23.4551L45.8964 44.6814Z"
      fill="black"
    />
    <path
      d="M493.191 493.406C493.02 492.858 493.615 492.384 494.155 492.638L514.754 503.154C514.8 503.174 514.861 503.211 514.925 503.217L537.55 508.41C538.134 508.544 538.27 509.291 537.757 509.586L518.115 521.585C518.08 521.613 518.028 521.655 517.993 521.683L502.082 538.07C501.67 538.483 500.921 538.224 500.898 537.644L499.954 515.147C499.96 515.085 499.948 515.037 499.937 514.989L493.191 493.406Z"
      fill="black"
    />
    <line
      x1="41.6024"
      y1="496.232"
      x2="496.602"
      y2="41.2322"
      stroke="black"
      strokeWidth="5"
    />
    <path
      d="M492.474 44.6814C492.275 45.22 492.847 45.723 493.398 45.4951L514.488 36.002C514.535 35.9842 514.598 35.9502 514.662 35.9472L537.514 31.8693C538.104 31.7642 538.276 31.0243 537.778 30.7048L518.749 17.7575C518.715 17.728 518.665 17.6836 518.631 17.654L503.543 0.507018C503.151 0.0735365 502.391 0.295581 502.339 0.874124L500.293 23.2982C500.297 23.3604 500.283 23.4077 500.269 23.4551L492.474 44.6814Z"
      fill="black"
    />
    <path
      d="M45.1786 493.406C45.3505 492.858 44.7552 492.384 44.2156 492.638L23.6162 503.154C23.5698 503.174 23.5089 503.211 23.445 503.217L0.820284 508.41C0.236514 508.544 0.100469 509.291 0.613568 509.586L20.2546 521.585C20.2897 521.613 20.3422 521.655 20.3772 521.683L36.2883 538.07C36.7002 538.483 37.4491 538.224 37.4725 537.644L38.4163 515.147C38.4102 515.085 38.4216 515.037 38.433 514.989L45.1786 493.406Z"
      fill="black"
    />
  </svg>
);

export default SpawningCross;
