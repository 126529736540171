"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import isUrl from "is-url";
import { useDispatch } from "react-redux";

import { IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReportIcon from "@mui/icons-material/Report";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import cn from "classnames";
import styles from "./DataGridCard.module.scss";
import CheckBox from "../CheckBox/CheckBox";
import BrokenStateDataGridCard from "../BrokenStateDataGridCard/BrokenStateDataGridCard";
import { setMoreLikeThisModalInfo } from "../../app/GlobalRedux/Features/moreLikeThisModalSlice";
import { AppDispatch } from "../../app/GlobalRedux/store";
import { ItemDataType } from "@/types";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import mixpanel from "mixpanel-browser";
import Logging from "@/utils/Logging";
import { usePathname } from "next/navigation";
import StatusBadge from "./StatusBadge/StatusBadge";

interface DataGridCardProps {
  id: number;
  url: string | null;
  type?: ItemDataType;
  isChecked: boolean;
  caption: string | null;
  is_in_dataset?: boolean;
  source?: string;
  status?: "pending" | "rejected" | "approved";
  handleCheckBoxClick: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, id: number) => void;
}

const DataGridCard: React.FC<DataGridCardProps> = ({
  id,
  url,
  type,
  isChecked,
  caption,
  is_in_dataset,
  source,
  status,
  handleCheckBoxClick,
  handleKeyDown,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [brokenItemId, setBrokenItemId] = useState<number>();
  const pathname = usePathname();
  const { hash } = window.location;
  const [isLocallyChecked, setIsLocallyChecked] = useState(isChecked);

  const [urlCopied, setUrlCopied] = useState(false);

  const isDoNotTrainList =
    pathname === "/dashboard" && (!hash || hash === "#0");

  useEffect(() => {
    if (brokenItemId === id && !isDoNotTrainList) {
      setIsLocallyChecked(false);
      return;
    }

    setIsLocallyChecked(isChecked);
  }, [brokenItemId, isChecked, id, isDoNotTrainList]);

  if (!url || !isUrl(url || "")) {
    return null;
  }

  const host = new URL(url).host;

  const dataSpecificElement: Record<ItemDataType, React.ReactNode> = {
    [ItemDataType.VIDEO]: (
      <div className={styles.videoPlayerWrapper}>
        <video
          src={url}
          controls
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <track kind="captions" />
        </video>
      </div>
    ),
    [ItemDataType.AUDIO]: (
      <div className={styles.audioPlayerWrapper}>
        {" "}
        <AudioPlayer url={url} className={styles.audioPlayer} />
      </div>
    ),
    // code type isnt supported right now
    [ItemDataType.CODE]: <span className={styles.codePlaceholder}>code</span>,
    [ItemDataType.TEXT]: null,
    [ItemDataType.IMAGE]: (
      <Image
        src={url}
        alt={"no caption found"}
        width={0}
        height={0}
        sizes="100vw"
        fill
        style={{
          objectFit: "contain",
        }}
        onError={() => {
          setBrokenItemId(id);
        }}
        unoptimized
      />
    ),
    [ItemDataType.OTHER]: (
      <Image
        src={url}
        alt={"no caption found"}
        width={0}
        height={0}
        sizes="100vw"
        fill
        style={{
          objectFit: "contain",
        }}
        onError={() => {
          setBrokenItemId(id);
        }}
        unoptimized
      />
    ),
  };

  const handleShare = () => {
    if (window && typeof window !== "undefined") {
      const shareUrl = `${window.location.origin}/share?url=${url}`;

      navigator.clipboard
        .writeText(shareUrl)
        .then(() => {
          setUrlCopied(true);
        })
        .catch((err) => {
          Logging.event("Clipboard Error", {
            page: "DataGridCard",
            err,
          });
        });
    }

    setTimeout(() => {
      setUrlCopied(false);
    }, 2000);
  };

  const handleMoreLikeThis = async (url: string) => {
    mixpanel.track("More Like This", {
      type,
      host,
    });

    await dispatch(
      setMoreLikeThisModalInfo({ isOpen: true, image_url: url, caption })
    );
  };

  const sourceMessage: Record<string, string> = {
    EXTENSION:
      "This item was found in the extension results and has been preemptively opted out",
    ["LAION-5B"]: " This item was found in the Laion-5B dataset",
  };

  return (
    <div key={id} className={styles.dataGridCardWrapper}>
      {(type || is_in_dataset) && (
        <div className={styles.cardHeader}>
          <span className={styles.typeLozenge}>{type}</span>
          {source && (
            <Tooltip title={sourceMessage[source]}>
              <span className={styles.sourceLozenge}>{source}</span>
            </Tooltip>
          )}

          {is_in_dataset && (
            <Tooltip title="Image found in dataset">
              <ReportIcon />
            </Tooltip>
          )}
        </div>
      )}
      <div className={styles.elementWrapper}>
        <div className={styles.dataGridCard}>
          {brokenItemId === id ? (
            <BrokenStateDataGridCard />
          ) : (
            <>{dataSpecificElement[type || ItemDataType.IMAGE]}</>
          )}

          {status ? <StatusBadge status={status} /> : null}
        </div>
      </div>

      <div className={styles.captionWrapper}>
        <div
          className={cn(styles.captionButton, {
            [styles.noCaption]: !caption,
          })}
        >
          {caption || "no caption found"}
        </div>
        <div className={styles.sourceWrapper}>
          <div className={styles.source}>{host}</div>

          <IconButton href={url} target="_blank">
            <OpenInNewIcon fontSize="small" sx={{ color: "gray" }} />
          </IconButton>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.cardFooter}>
        <div className={styles.actionsBar}>
          <CheckBox
            id={id}
            isChecked={isLocallyChecked}
            handleCheckBoxClick={handleCheckBoxClick}
            handleKeyDown={handleKeyDown}
            disabled={brokenItemId === id && !isDoNotTrainList}
          />
        </div>
      </div>
    </div>
  );
};

export default DataGridCard;
