"use client";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import loadingAnimation from "@/assets/lottie/loadingAnimation.json";
import styles from "./LoadingState.module.scss";
import { useAppSelector } from "../../app/GlobalRedux/store";
import { SearchType } from "@/types";

const LoadingState = () => {
  const searchType = useAppSelector((state) => state.search.type);

  const messagesByType: Record<SearchType, string> = {
    [SearchType.DOMAIN]:
      "We're searching for all images on this domain, so it may take a minute",
    [SearchType.TEXT]:
      "We're searching 5.8 billion captions, so this may take a minute",
    [SearchType.IMAGE]:
      "Spawning does not retain any images used to search this database",
    [SearchType.LITERATURE]:
      "We're searching Books3, a dataset containing books, magazines, and journals for LLM training.",
    [SearchType.NONE]: "Just a second while we gather everything",
  };

  return (
    <div className={styles.wrapper}>
      <Lottie
        animationData={loadingAnimation}
        autoplay
        loop
        style={{ height: "50%", width: "50%" }}
      />

      <p className={styles.message}>{messagesByType[searchType]}</p>
    </div>
  );
};

export default LoadingState;
