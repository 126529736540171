"use client";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import loadingAnimation from "@/assets/lottie/loadingAnimation.json";
import styles from "./LoadingState.module.scss";
import { useAppSelector } from "../../app/GlobalRedux/store";
import { SearchType } from "@/types";

const LoadingState = () => {

  return (
    <div className={styles.lazyWrapper}>
      <Lottie
        animationData={loadingAnimation}
        autoplay
        loop
        style={{ height: "100%", width: "100%" }}
      />

      <p className={styles.message}>Loading more results...</p>
    </div>
  );
};

export default LoadingState;
