import React from "react";
import styles from "./BrokenStateDataGridCard.module.scss";
import LogoIcon from "../../../public/assets/icons/LogoIcon";

interface BrokenStateDataGridCardProps {}

const BrokenStateDataGridCard: React.FC<
  BrokenStateDataGridCardProps
> = ({}) => (
  <div className={styles.brokenCardWrapper}>
    <div className={styles.brokenCard}>
      <span className={styles.header}>404</span>
      <span className={styles.text}>
        We could not load this image at this time
      </span>
      <span className={styles.logoWrapper}>
        <LogoIcon />
      </span>
    </div>
  </div>
);

export default BrokenStateDataGridCard;
