import React, { useState } from "react";
import cn from "classnames";
import { ClickAwayListener, IconButton, Popper, Tooltip } from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import GppGoodIcon from "@mui/icons-material/GppGood";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import Button from "@/components/Button/Button";
import styles from "./StatusBadge.module.scss";

type Status = "pending" | "rejected" | "approved";

type StatusInformation = {
  value: string;
  tooltipText?: string;
  icon: JSX.Element;
};

interface StatusBadgeProps {
  status: "pending" | "rejected" | "approved";
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const statusInformation: Record<Status, StatusInformation> = {
    pending: {
      value: "pending",
      tooltipText: "This item is pending review",
      icon: (
        <HourglassBottomIcon
          className={styles.icon}
          sx={{
            color: "#000",
          }}
          fontSize="small"
        />
      ),
    },
    rejected: {
      value: "rejected",
      tooltipText: "Click to learn more about why this item was rejected",
      icon: (
        <RemoveModeratorIcon
          sx={{
            color: "#000",
          }}
          fontSize="small"
        />
      ),
    },
    approved: {
      value: "approved",
      tooltipText: `This item is approved and available in our "Do Not Train" registry`,
      icon: (
        <GppGoodIcon
          className={styles.icon}
          sx={{
            color: "#000",
          }}
          fontSize="small"
        />
      ),
    },
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <div
          className={cn(styles.triangle, [
            styles[statusInformation[status].value],
          ])}
        />
        <div>
          {statusInformation[status].value === "rejected" ? (
            <Tooltip title={statusInformation[status].tooltipText}>
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setIsOpen(!isOpen);
                }}
                className={styles.iconButton}
                aria-describedby={`popper-id-${status}`}
              >
                {statusInformation[status].icon}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={statusInformation[status].tooltipText}>
              {statusInformation[status].icon}
            </Tooltip>
          )}

          <Popper anchorEl={anchorEl} open={isOpen} id={`popper-id-${status}`}>
            <ClickAwayListener
              onClickAway={() => {
                setAnchorEl(null);
                setIsOpen(false);
              }}
            >
              <div className={styles.rejectedPopperContent}>
                <p>
                  This item has been manually reviewed and rejected. This
                  usually means we do not believe you have the appropriate
                  permissions to opt-out this work.
                </p>
                <p>
                  If you believe this decision was made by mistake, please
                  contact us.
                </p>

                <div className={styles.popoverButtons}>
                  <Button
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      setAnchorEl(null);
                      setIsOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      window.open("mailto:info@spawning.ai");
                    }}
                  >
                    Contact us
                  </Button>
                </div>
              </div>
            </ClickAwayListener>
          </Popper>
        </div>
      </div>
    </div>
  );
};

export default StatusBadge;
