import React from "react";
import styles from "./Table.module.scss";
import DataGridCard from "../DataGridCard/DataGridCard";
import UrlDataGridCard from "../SearchResultPages/LiteratureSearchResultPage/UrlDataGridCard/UrlDataGridCard";
import { ItemDataType } from "@/types";
import LazyLoadingState from "../states/LazyLoadingState";

type DataItem = {
  id?: number;
  caption?: string | null;
  url: string | null;
  isChecked: boolean;
  baseUrl?: string | null;
  isDuplicate?: boolean;
  ranking?: number;
  similarity?: number;
  urlIsValid?: boolean;
  title?: string | null;
  authors?: string[] | null;
  type?: ItemDataType;
  is_in_dataset?: boolean;
  source?: string;
  is_rejected?: boolean;
  is_reviewed?: boolean;
};

interface TableProps {
  dataToDisplay: DataItem[];
  shouldShowBadge?: boolean;
  handleCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleUrlCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>,
    url: string
  ) => void;
  handleUrlKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement>,
    url: string
  ) => void;
  hasMore?: boolean;
}

const Table: React.FC<TableProps> = ({
  dataToDisplay,
  shouldShowBadge = false,
  handleCheckBoxClick,
  handleKeyDown,
  handleUrlCheckBoxClick,
  handleUrlKeyDown,
  hasMore
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.itemsWrapper}>
        {dataToDisplay.map(
          (
            {
              id,
              url,
              caption,
              isChecked,
              type,
              title,
              authors,
              is_in_dataset,
              source,
              is_rejected,
              is_reviewed,
            },
            idx
          ) => {
            const determineStatus = (): "pending" | "rejected" | "approved" => {
              if (!is_reviewed) {
                return "pending";
              }

              if (is_rejected && is_reviewed) {
                return "rejected";
              }

              if (is_reviewed && !is_rejected) {
                return "approved";
              }

              return "pending";
            };

            const status = determineStatus();

            if (handleUrlCheckBoxClick && handleUrlKeyDown) {
              return (
                <UrlDataGridCard
                  key={`${url}-${idx}`}
                  url={url}
                  type={type}
                  title={title}
                  caption={caption || "no caption found"}
                  authors={authors}
                  isChecked={isChecked}
                  source={source}
                  is_in_dataset={is_in_dataset}
                  handleCheckBoxClick={handleUrlCheckBoxClick}
                  handleKeyDown={handleUrlKeyDown}
                />
              );
            }

            if (handleCheckBoxClick && handleKeyDown) {
              return (
                <DataGridCard
                  key={`${url}-${idx}`}
                  url={url}
                  type={type}
                  caption={caption || "no caption found"}
                  id={id || 0}
                  isChecked={isChecked}
                  source={source}
                  status={shouldShowBadge ? status : undefined}
                  handleCheckBoxClick={handleCheckBoxClick}
                  handleKeyDown={handleKeyDown}
                />
              );
            }
          }
        )}

        {
          hasMore ? (
            <LazyLoadingState />
          ) : null
        }
      </div>
    </div>
  );
};

export default Table;
