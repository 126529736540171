import React from "react";
import styles from "./UrlCheckBox.module.scss";

interface UrlCheckBoxProps {
  url?: string;
  isChecked?: boolean;
  disabled?: boolean;
  handleCheckBoxClick?: (
    e: React.ChangeEvent<HTMLInputElement>,
    url: string
  ) => void;
  handleKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement>,
    url: string
  ) => void;
}

const UrlCheckBox: React.FC<UrlCheckBoxProps> = ({
  url,
  isChecked,
  disabled,
  handleCheckBoxClick,
  handleKeyDown,
}) => (
  <label htmlFor={`checkbox-input-${url}`} className={styles.itemActions}>
    <span>
      {url && (
        <input
          id={`checkbox-input-${url}`}
          className={styles.checkboxInput}
          type="checkbox"
          disabled={disabled}
          checked={isChecked}
          onChange={(e) => handleCheckBoxClick && handleCheckBoxClick(e, url)}
          onKeyDown={(e) => handleKeyDown && handleKeyDown(e, url)}
        />
      )}
    </span>
  </label>
);

export default UrlCheckBox;
